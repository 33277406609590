import React from 'react';
import svgImage from '../assets/img/cooking.svg';
function ErorrPage() {

    return (
        <div style={{ background: '#f5f5f5', width: '100%', height: '100vh' }}>

            <div className="container osahan-coming-soon py-5 d-flex justify-content-center align-items-center">

                <div className="osahan-text text-center">

                    <img src={svgImage} width='300' height='300' alt='e-menu' />


                    <h2 className="text-primary mb-3 font-weight-light">Page not <b>found</b></h2>
                    <p className="lead small mb-0">Oops! Looks like you followed a bad link.</p>
                    <p className="mb-5">If you think this is a problem with us, please <a href="#">tell us</a>.</p>

                </div>
            </div>
        </div>
    )
}

export default ErorrPage