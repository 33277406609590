import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";






function Home() {
    const params = new URLSearchParams(window.location.search);
    const [hostData, setHostData] = useState('');
    const [brandDetails, setBrandDetails] = useState([]);
    const [menu, setMenu] = useState([]);
    const [design, setDesign] = useState([]);
    let [loading, setLoading] = useState(true);
    const brandKey = params.get('id_brand');
    const hexCode = '#FFFFFF';
    const navigate = useNavigate();



    useEffect(() => {

        setLoading(true);
        const fetchDataBrandMenu = () => {

            return fetch(`https://api.emenu.ws/e_menu/api/v1/menu/brandMenu/${brandKey}`)

                .then((response) => response.json())
                .then((data) => {

                    if (data.length == 1) {
                        navigate(`/Menu?menu_key=${data[0].menuKey}&brandKey=${brandKey}`);
                    }



                    setMenu(data)
                }
                );

        }


        const checkAccountIsActive = () => {

            return fetch(`https://api.emenu.ws/e_menu/api/v1/menu/checkBreandIsActive/${brandKey}`)

                .then((response) => response.json())
                .then((data) => {

                    if (data !== 1) {

                        navigate('/ErorrPage');
                    }


                }
                );

        }

        const fetchDateDesign = async () => {
            await fetch(`https://api.emenu.ws/e_menu/api/v1/menu/design/${brandKey}`)
                .then((response) => response.json())
                .then((data) => {
                    setDesign(data);
                });

        }

        const getDataHostData = async () => {

            const res = await axios.get('https://geolocation-db.com/json/')

            setHostData(res.data)
            sessionStorage.setItem("hostData", JSON.stringify(res.data));
        }

        const getBrandDetails = async () => {
            const res = await axios.get(`https://api.emenu.ws/e_menu/api/v1/menu/shopBrand/${brandKey}`)

            setBrandDetails(res.data)

        }


        checkAccountIsActive()
        getDataHostData();
        fetchDataBrandMenu();
        fetchDateDesign();
        getBrandDetails();
        setLoading(false);
    }, []);




    var currentLanguage = sessionStorage.getItem("currentLanguage");
    if (currentLanguage == null) {
        currentLanguage = "en"
    }
    const [displayLanguage, setDisplayLanguage] = useState(false);
    const [displayMenu, setDisplayMenu] = useState(false);


    const { t, i18n } = useTranslation();
    const dialogFuncMap = {
        'displayLanguage': setDisplayLanguage,
        'displayMenu': setDisplayMenu
    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const getValueColor = (keyValue) => {
        let color = '';

        design?.designHomePage?.forEach(element => {

            if (element.key === keyValue) {

                color = `#${element.vlaue}`.toUpperCase();
            }
        });

        return color;
    }








    return (
        <>
            <div style={{ direction: `${currentLanguage === 'en' ? 'ltr' : 'rtl'}`, }} >


                <div className="flex justify-content-center flex-wrap" style={{ backgroundColor: `${getValueColor('HOME_PAGE_BACKGROUND_COLOR')} `, height: '100vh' }} >
                    <ClipLoader color="#000000" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                    <div>
                        <a href="/#">
                            <img className='m-7 flex align-items-center justify-content-center' src={brandDetails.logoFile} alt="" />

                        </a><br />
                        <div className="spinner"></div>
                    </div>
                </div>


                <div className='bottomDiv'>

                    <div className="grid">
                        <Button icon="fa fa-solid fa-language" style={{ fontSize: '30px', backgroundColor: `${getValueColor('LANGUAGE_BUTTON_BACKGROUND_COLOR')} `, color: `${getValueColor('LANGUAGE_BUTTON_ICON_COLOR')}` }} className=" p-button-rounded p-button-secondary m-6  border-0 " aria-label="language" onClick={() => onClick('displayLanguage', 'bottom')} />
                    </div>
                    <div className="grid">
                        <div className="col-12">

                            <Button label={t('Menu')} style={{ backgroundColor: `${getValueColor('HOME_BUTTON_BACKGROUND_COLOR')} `, color: `${getValueColor('HOME_BUTTON_TEXT_COLOR')} ` }} className="w-full bg-orange border-0 border-noround" onClick={() => onClick('displayMenu', 'bottom')} />
                        </div>
                    </div>


                    <Dialog visible={displayLanguage} modal style={{ width: '70%' }} onHide={() => onHide('displayLanguage')}
                        draggable={false} resizable={false}>
                        <div className="grid">
                            <div className="col-6"  >
                                <Button className='w-full' style={{ background: `${currentLanguage === 'en' ? '' : 'green'}   ` }} label='Arabic' onClick={() => {
                                    i18n.changeLanguage('ar')
                                    sessionStorage.setItem("currentLanguage", i18n.language);
                                    onHide('displayLanguage');

                                }}></Button>
                            </div>
                            <div className="col-6">
                                <Button className='w-full' style={{ background: `${currentLanguage === 'en' ? 'green' : ''}   ` }} label='English' onClick={() => {
                                    i18n.changeLanguage('en')
                                    sessionStorage.setItem("currentLanguage", i18n.language);
                                    onHide('displayLanguage');
                                }}></Button>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog visible={displayMenu} modal style={{ width: '70%' }} onHide={() => onHide('displayMenu')}
                        draggable={false} resizable={false}>
                        <div className="grid">



                            {menu.map((menuObj, index) => {



                                return (
                                    <div className='col-12' key={index}>
                                        <Link to={`/Menu?menu_key=${menuObj.menuKey}&brandKey=${brandKey}`}>
                                            <Button className='w-full' style={{ backgroundColor: `${getValueColor('HOME_BUTTON_BACKGROUND_COLOR')} `, color: `${getValueColor('HOME_BUTTON_TEXT_COLOR')} ` }} label={`${currentLanguage === 'en' ? menuObj.name.descEn : menuObj.name.descAr}`} />
                                        </Link >
                                    </div>

                                );
                            })}
                        </div>
                    </Dialog>
                </div>
            </div>


        </>
    );
}
export default Home;
