
import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Menu from './components/Menu';
import DetailsItem from './components/DetailsItem';
import ErorrPage from './components/erorr';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/Home' element={<Home />}></Route>
          <Route path='/Menu' element={<Menu />}></Route>
          <Route path='/DetailsItem' element={<DetailsItem />}></Route>
          <Route path='/ErorrPage' element={<ErorrPage />}></Route>

        </Routes>
      </BrowserRouter>

    </>
  );
}
export default App;
