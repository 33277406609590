
import React, { useState, useEffect, } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useNavigate } from 'react-router-dom';
function MenuList() {


    const params = new URLSearchParams(window.location.search);
    const [itemMenu, setItemMenu] = useState([]);
    const brandKey = params.get('brandKey');
    var currentLanguage = sessionStorage.getItem("currentLanguage");
    if (currentLanguage == null) {
        currentLanguage = "en"
    }

    const navigate = useNavigate();

    const checkAccountIsActive = () => {

        return fetch(`https://api.emenu.ws/e_menu/api/v1/menu/checkBreandIsActive/${brandKey}`)

            .then((response) => response.json())
            .then((data) => {

                if (data !== 1) {
                    navigate('/ErorrPage');
                }


            }
            );

    }




    useEffect(() => {

        checkAccountIsActive();

        fetch(`https://api.emenu.ws/e_menu/api/v1/menu/allMenu/${brandKey}`)

            .then((response) => response.json())
            .then((data) => {


                setItemMenu(data)
            }
            );


    }, [])

    return (
        <>

            <div className="cat-slider" id="menuList">


                <div className="container testimonial-group ">
                    <div className="row text-center flex-nowrap ss ">
                        <div className="flex justify-content-center flex-wrap d-flex align-items-center " >

                            {itemMenu?.map((menu, index) => {

                                if (itemMenu.length > 1) {
                                    return (
                                        <div key={index}>

                                            <Link to={`/Menu?menu_key=${menu.menuKey}&brandKey=${brandKey}`} onClick={i => {
                                                window.history.push()
                                            }} >
                                                <div href="#" className=" rounded d-block p-2 text-center shadow-sm menuList"> <FontAwesomeIcon icon={menu?.menuIcon} /> {`${currentLanguage === 'en' ? menu.name.descEn : menu.name.descAr}`}  </div>
                                            </Link>
                                        </div>



                                    )
                                }
                            }) ?? ''}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MenuList