
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
function DetailsItem(props) {

    const [detailsItem, setDetailsItem] = useState();
    let [loading, setLoading] = useState(true);




    const getBrandDetails = async () => {
        const res = await axios.get(`https://api.emenu.ws/e_menu/api/v1/menu/itemDetails/${props.itemIdValue}`)

        setDetailsItem(res.data)


    }
    useEffect(() => {
        setLoading(true);
        getBrandDetails();
        setLoading(false);

    }, [])

    if (detailsItem !== undefined) {
        return (

            < div >


                <div className="osahan-recepie">
                    <ClipLoader color="#000000" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                    <div className="recepie-header">
                        <div className="position-absolute px-3 py-4 d-flex align-items-center w-100">



                            <div className="d-flex ml-auto"></div>
                        </div>
                        <img src={detailsItem.imageUrl} className="img-fluid w-100" alt="" />
                    </div>


                    <div className="recepie-body">
                        <div className="p-3">

                            <h2>{detailsItem?.name?.descEn ?? ''}</h2>

                            <h6>Price</h6>

                            {detailsItem.menuItemPriceList.map((element, index) => {

                                return (

                                    <div key={index}> {element.price} - {element.description} - {element?.lkAvailable?.descEn ?? ''}</div>


                                );

                            })}
                            <h6 className="font-weight-bold mt-4">Description</h6>
                            <p>{detailsItem?.descriptionApi?.descEn ?? ''}</p>

                        </div>
                    </div>

                </div></div >

        )

    } else {

        return (

            < div ></div>)

    }

}

export default DetailsItem