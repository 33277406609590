
import React, { useEffect, useState } from 'react'
import Zuck from 'zuck.js'
import Category from './Category'
import Item from './Item'
import Story from './Story'
import MenuList from './MenuList'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import ClipLoader from "react-spinners/ClipLoader";

var timestamp = function () {
    var timeIndex = 0;
    var shifts = [35, 60, 60 * 3, 60 * 60 * 2, 60 * 60 * 25, 60 * 60 * 24 * 4, 60 * 60 * 24 * 10];
    var now = new Date();
    var shift = shifts[timeIndex++] || 0;
    var date = new Date(now - shift * 1000);
    return date.getTime() / 1000;
};


function Menu() {
    let [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const params = new URLSearchParams(window.location.search);

    const menuKey = params.get('menu_key');
    var currentLanguage = sessionStorage.getItem("currentLanguage");
    if (currentLanguage == null) {
        currentLanguage = "en"
    }


    useEffect(() => {


        sessionStorage.setItem("currentStory", "");
        var currentStory = sessionStorage.getItem("currentStory");

        if (currentStory === "" || currentStory === null) {
            fetch(`https://api.emenu.ws/e_menu/api/v1/stories/allStories/${menuKey}`)
                .then((response) => response.json())
                .then(data => {

                    let myStory = [];
                    const newDate = [];
                    data.forEach((test) => {

                        let newArray = [];
                        test.items.forEach(el => {
                            newArray.push([el.name, el.type, el.length, el.src, el.src, !!el.seen, !!el.seen, el?.time ?? timestamp()])
                        });

                        const x = Zuck.buildTimelineItem(test.id, test.photo, test.name, test.link, timestamp(), newArray);
                        x['animation'] = test.animation;
                        newDate.push(x)
                    },

                        myStory.push(JSON.stringify(data)),


                        sessionStorage.setItem("currentStory", myStory),
                        setData(newDate)
                    )
                });
        } else {



            const newDate = [];
            var storage = JSON.parse(sessionStorage.getItem("currentStory"));
            storage?.forEach((test) => {
                debugger;
                let newArray = [];
                test.items.forEach(el => {
                    newArray.push([el.name, el.type, el.length, el.srcApiDefault, el.srcApiDefault, !!el.seen, !!el.seen, el?.time ?? timestamp()])
                });

                const x = Zuck.buildTimelineItem(test.id, test.photoApi, test.name, test.link, timestamp(), newArray);
                x['animation'] = test.animation;

                newDate.push(x)
            },

                setData(newDate))
        }
        setLoading(false);
        return () => {
            setData(undefined)
        }

    }, [])


    var currentLanguage = sessionStorage.getItem("currentLanguage");
    if (currentLanguage == null) {
        currentLanguage = "en"
    }
    const [displayLanguage, setDisplayLanguage] = useState(false);



    const { t, i18n } = useTranslation();
    const dialogFuncMap = {
        'displayLanguage': setDisplayLanguage,

    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    return (
        <div style={{ background: '#ddd' }}>
            <div className="container mt-5">

                <ClipLoader color="#000000" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />

                <div id='site-header' style={{ background: '#ddd' }}>
                    <MenuList />
                    {data && data.length > 0 ? <Story data={data} /> : null}
                    <div id='catDiv'>
                        <Category />
                    </div>

                </div>

                <br></br>

                <Item></Item>


                <div className='bottomDiv' style={{ direction: `${currentLanguage === 'rtl' ? '' : 'ltr'}   ` }}>

                    <div className="grid">
                        <Button icon="fa fa-solid fa-language" style={{ fontSize: '30px' }} className=" p-button-rounded p-button-secondary m-6 bg-orange-600 border-0 " aria-label="language" onClick={() => onClick('displayLanguage', 'bottom')} />
                    </div>



                    <Dialog visible={displayLanguage} modal style={{ width: '70%' }} onHide={() => onHide('displayLanguage')}
                        draggable={false} resizable={false}>
                        <div className="grid">
                            <div className="col-6"  >
                                <Button className='w-full' style={{ background: `${currentLanguage === 'en' ? '' : 'green'}   ` }} label='Arabic' onClick={() => {
                                    i18n.changeLanguage('ar')
                                    sessionStorage.setItem("currentLanguage", i18n.language);
                                    onHide('displayLanguage');

                                }}></Button>
                            </div>
                            <div className="col-6">
                                <Button className='w-full' style={{ background: `${currentLanguage === 'en' ? 'green' : ''}   ` }} label='English' onClick={() => {
                                    i18n.changeLanguage('en')
                                    sessionStorage.setItem("currentLanguage", i18n.language);
                                    onHide('displayLanguage');
                                }}></Button>
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>

        </div >
    )
}

export default Menu