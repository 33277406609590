

import React, { Component } from 'react';
import Zuck from 'zuck.js'



class Story extends Component {
    constructor(props) {
        super(props);
        this.storiesElement = null;
        this.storiesApi = null;
        this.state = {

            stories: this.props.data,

        }


    }


    componentDidMount() {

        this.storiesApi = new Zuck(this.storiesElement, {
            backNative: true,
            previousTap: true,
            skin: 'snapgram',
            autoFullScreen: false,
            avatars: true,
            paginationArrows: false,
            list: false,
            cubeEffect: true,
            localStorage: true,
            stories: this.state.stories,
            reactive: true,
            callbacks: {
                onDataUpdate: function (currentState, callback) {
                    this.setState(state => {
                        state.stories = currentState;

                        return state;
                    }, () => {
                        callback();
                    });
                }.bind(this)
            }
        });
    }




    render() {

        var currentLanguage = sessionStorage.getItem("currentLanguage");
        if (currentLanguage == null) {
            currentLanguage = "en"
        }


        const timelineItems = []

        this.state.stories.forEach((story, storyId) => {
            const storyItems = [];


            story.items.forEach((storyItem) => {

                storyItems.push(

                    <li key={storyItem.id} data-id={storyItem.id} data-time={storyItem.time} className={(storyItem.seen ? 'seen' : '')}>
                        <a href={storyItem.src} data-type={storyItem.type} data-length={storyItem.length} data-link={storyItem.link} >
                            <img src={storyItem.preview} alt="" />
                        </a>
                    </li>
                );

            });

            let arrayFunc = story.seen ? 'push' : 'unshift';

            timelineItems[arrayFunc](
                <div className={(story.seen ? 'story ' : 'story ').concat((story.animation ? 'pulse2' : ''))} key={storyId} data-id={storyId} data-last-updated={story.lastUpdated} data-photo={story.photo}>
                    <a className="item-link" >
                        <span className="item-preview">
                            <img src={story.photo} alt="" />
                        </span>
                        <span className="info" itemProp="author" itemScope="" itemType="http://schema.org/Person">
                            <strong className="name" itemProp="name">{story.name}</strong>
                            <span className="time">{story.lastUpdated}</span>
                        </span>
                    </a>

                    <ul className="items">
                        {storyItems}
                    </ul>
                </div>
            );


        });

        return (
            <div style={{ direction: `${currentLanguage === 'en' ? 'ltr' : 'rtl'}` }}>
                <div ref={node => this.storiesElement = node} id="stories-react" className="storiesWrapper">
                    {timelineItems}
                </div>


            </div>




        );
    }


}

export default Story