import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

function Category() {

    const ref = useRef(null);

    const params = new URLSearchParams(window.location.search);
    const [category, setCategory] = useState([]);



    const menuKey = params.get('menu_key');
    var currentLanguage = sessionStorage.getItem("currentLanguage");
    if (currentLanguage == null) {
        currentLanguage = "en"
    }


    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const fetchData = () => {

        return fetch(`https://api.emenu.ws/e_menu/api/v1/menu/itemSection/${menuKey}`)

            .then((response) => response.json())
            .then((data) => {

                localStorage.setItem("currentCategory", JSON.stringify(data));
                setCategory(data)
            }
            );
    }

    useEffect(() => {
        fetchData();
    }, [])




    return (

        <div>

            <div style={{ direction: `${currentLanguage === 'en' ? 'ltr' : 'rtl'}` }}>
                <div className="container">
                    <div className='weapperA '>
                        {category.map((categoryObj, index) => {
                            return (

                                <div className='item' key={index}>
                                    <a href={`#Section${categoryObj.sectionId}`}>
                                        <div className=" m-1">
                                            <div className="p-1 rounded shadow-sm bg-white">
                                                <div>
                                                    <div>

                                                        <img alt="#" src={categoryObj.imageUrl} style={{ maxWidth: '60px', maxHeight: '60px' }} className="img-fluid order_img rounded" />
                                                        <p className=" font-weight-bold"> {`${currentLanguage === 'en' ? categoryObj.name.descEn : categoryObj.name.descAr}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            )
                        })}
                    </div>
                </div>

            </div>
        </div>


    )
}

export default Category