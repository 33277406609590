
import React, { useState, useEffect, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fontawesome from '@fortawesome/fontawesome'
import { Link } from 'react-router-dom';
import { faBeer } from '@fortawesome/fontawesome-free-solid';
import '../assets/js/script.js'
import { Dialog } from 'primereact/dialog';
import DetailsItem from './DetailsItem.js';



function Item() {





    const [item, setItem] = useState([]);
    const [itemIdValue, setItemIdValue] = useState("");
    const [displayDetailItem, setDisplayDetailItem] = useState(false);
    var currentLanguage = sessionStorage.getItem("currentLanguage");
    if (currentLanguage == null) {
        currentLanguage = "en"
    }




    const currentCategory = JSON.parse(localStorage.getItem("currentCategory"));

    fontawesome.library.add(faBeer);





    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const menuKey = params.get('menu_key');

        const getData1 = async () => {
            await fetch(`https://api.emenu.ws/e_menu/api/v1/menu/itemMenu/${menuKey}`)

                .then((response) => response.json())
                .then((data) => {

                    setItem(data)

                    myItems.push(JSON.stringify(data))
                    sessionStorage.setItem("currentItem", myItems)


                },);



        };

        const getData2 = async () => {
            await fetch(`https://api.emenu.ws/e_menu/api/v1/menu/menu/${menuKey}`)
                .then((response) => response.json())
                .then((data) => {

                    var lastUpdateApi = sessionStorage.getItem("lastUpdateMenu");
                    if (lastUpdateApi === null) {
                        sessionStorage.setItem("lastUpdateMenu", data);
                    } else {
                        if (lastUpdateApi !== data + "") {
                            sessionStorage.setItem("lastUpdateMenu", data);
                            getData3();
                        } else {
                            setItem(JSON.parse(currentItem));
                        }
                    }
                },
                );



        };
        const getData3 = async () => {
            await fetch(`https://api.emenu.ws/e_menu/api/v1/menu/itemMenu/${menuKey}`)

                .then((response) => response.json())
                .then((data) => {

                    setItem(data)
                    myItems.push(JSON.stringify(data))
                    sessionStorage.setItem("currentItem", myItems)


                },);


        };





        var currentItem = sessionStorage.getItem("currentItem");
        let myItems = [];
        if (currentItem === "" || currentItem === null) {
            getData1();
        } else {
            getData2();
        }
    }, []);


    const clickSaveLog = (itemObj) => {

        var hostData = sessionStorage.getItem("hostData");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ itemObj, hostData })
        };

        fetch(`https://api.emenu.ws/e_menu/api/v1/menu/saveLog`, requestOptions)
            .then(response => response.json())
            .then(data => JSON.stringify(data));
    }

    const showDialog = (itemObj) => {


        setItemIdValue(itemObj.itemId);
        onClick('displayDetailItem', 'bottom');

        //  clickSaveLog(itemObj);
    }

    const dialogFuncMap = {
        'displayDetailItem': setDisplayDetailItem
    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }






    return (
        <div style={{ direction: `${currentLanguage === 'en' ? 'ltr' : 'rtl'}` }}>
            <div className='vertical-side'></div>
            <div className="container">



                {currentCategory?.map((category, indexCat) => {

                    return (


                        <div key={indexCat}>



                            <h2 id={`Section${category.sectionId}`}>
                                {`${currentLanguage === 'en' ? category.name.descEn : category.name.descAr}`}
                            </h2>
                            <br />
                            <div className='grid'>

                                {item.map((itemObj, index) => {



                                    if (itemObj.sectionId.sectionId === category.sectionId) {
                                        return (



                                            <div className='col-12 md:col-6 lg:col-4' key={index}>

                                                <div className="pb-3" onClick={() => { showDialog(itemObj) }}
                                                >
                                                    <div className="p-3 rounded shadow bg-white" style={{ height: 'auto' }}>
                                                        <div className="d-flex align-content-center" style={{ marginTop: '15px' }} >
                                                            <div className={`text-muted ${currentLanguage === 'en' ? 'mr-3' : 'ml-3'}`}>
                                                                <img alt="#" src={itemObj.imageUrl} style={{ maxWidth: '122px' }} className="img-fluid order_img rounded" />

                                                            </div>
                                                            <div >

                                                                <div className="pl-2 font-weight-bold" >{`${currentLanguage === 'en' ? itemObj.name.descEn : itemObj.name.descAr}`}</div>

                                                                <div className="pl-2 font-weight-bold" dangerouslySetInnerHTML={{ __html: `${currentLanguage === 'en' ? itemObj.descriptionApi.descEn : itemObj.descriptionApi.descAr}` }} ></div>
                                                                <div>{itemObj.menuItemPriceList.map((itemPrice, indexPrice) => {
                                                                    return (
                                                                        <div key={indexPrice} className="pl-2">
                                                                            <FontAwesomeIcon icon={faBeer} />
                                                                            <div className={`${currentLanguage === 'en' ? 'pl-2' : 'pr-2'}`} style={{ display: 'initial' }}>
                                                                                {`${itemPrice.price} ${itemPrice.description}`}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )

                                    } else {

                                        return (

                                            <div key={index}></div>
                                        );
                                    }


                                })}
                            </div>


                            <Dialog visible={displayDetailItem} modal style={{ width: '95%' }} onHide={() => onHide('displayDetailItem')}
                                draggable={false} resizable={false}>
                                <DetailsItem itemIdValue={itemIdValue}></DetailsItem>
                            </Dialog>

                        </div>
                    )
                }) ?? ''}





            </div>

        </div>
    )
}

export default Item